<template>
	<div
		class="flex-column full-height bg-white position-relative"
	>
		<div class="full-height overflow-y-auto">

		</div>
	</div>
</template>
<script>

export default {
	name: 'PaymentItem'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '결제목록'
				, title: true
				, bottom: true
				, top: false
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}
	, computed: {
		is_disabled: function(){
			let t = true

			return t
		}
	}
	, methods: {
		getPaymentList: async function(){
			try{
				this.$bus.$emit('on')
				let result = await this.$Axios({
					method: 'post'
					, url: '/member/getPaymentList'
					, data: {

					}
				})
				if(result.success){
					this.is_on_result = true
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('off')
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>